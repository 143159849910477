import {
  getComparisonObjectsFromStorage,
  getProgramHeaderCard,
} from '../../assets/js/modules/core-compare-module.js';

// This needs to store / retrieve an array of object.
// 
const cart = getComparisonObjectsFromStorage() || [];
// console.log('the cart that is stored in the compare-cart.js file');
// console.log(cart);
$( function() {
  const comparistonTrayElement = document.querySelector('#comparisonTray');

  cart.forEach((programObject, index)=>{
    let divElement = document.createElement('div');

    let programHeader = getProgramHeaderCard(programObject);
    divElement.innerHTML = programHeader;
    comparistonTrayElement.appendChild(divElement);
  })

  $('#comparisonTray').slideToggle( "fast" );
  $('#viewPrograms').on('click', function () {
    $('#comparisonTray').slideToggle( "slow" );
  })
});
